//FlowInterface/JSONOutput.js
import React from 'react';
import PropTypes from 'prop-types';
import DataTable from './Components/DataTable';

const JSONOutput = ({ output = {} , select= false }) => {
  const displayObject = output.value ? output.value : output;
  const displayName = output.name ? output.name : 'Result';
  let selectionMode;
  if (select) {
    selectionMode = select == 'multiple' ? 'multiple' : true;
  } else {
    selectionMode = 'none';
  }
  return (
    <div style={{ marginBottom: '10px' }}>
      <strong>{displayName}:</strong><br/>
      <pre>
        <DataTable jsonData={displayObject} selectionMode = {selectionMode} />
      </pre>
    </div>
  );
};
JSONOutput.propTypes = {
  output: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.any
  }),
  select: PropTypes.bool
};


export default React.memo(JSONOutput);
