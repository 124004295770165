// FlowInterface/OutputArea.js
import React, { useCallback, useEffect, useRef } from 'react';
import { Chat, Channel, Window, MessageList, TypingIndicator } from 'stream-chat-react';
import { useStreamClient } from '../../../clients/StreamClient';
import { useDispatch, useSelector } from 'react-redux';
import FlowMessage from './FlowMessage';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import LoadingScreen from '../../../components/LoadingScreen';
import { selectFlow } from '../../../redux/selectors';
import { clearFlowRun } from '../../../redux/slices/flowSlice';
import FlowInfo from './Components/FlowInfo';  // Import the new component

import './styles/OutputArea.css';
import './styles/chat.css';

const OutputArea = React.memo(({ isLogVisible, setIsLogVisible }) => {
  const flow = useSelector(selectFlow);
  const { chatClient, channel, loading } = useStreamClient();
  const messagesEndRef = useRef(null);
  const dispatch = useDispatch();
  let isTyping = false;

  const scrollToBottom = (ref=messagesEndRef) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  

  // OutputArea.js
const customRenderMessages = useCallback((options) => {
  const elements = options.messages.map((message) => (
    <CSSTransition key={message.id} timeout={800} classNames="message">
      <li>
        <FlowMessage message={message}  />
      </li>
    </CSSTransition>
  ));

  //let isTyping = false;
  if(options.typing) {
  const typingUsers = Object.values(options.typing);
   isTyping = typingUsers.length > 0;
  }
  
  return (
    <>
      <TransitionGroup component="ul">
        {elements}
      </TransitionGroup>
      <div ref={messagesEndRef} />
    </>
  );
}, []);


  const clearMessages = async () => {
    try {
      if (channel) {
        await channel.truncate();
        console.log('Channel truncated');
      }
    } catch (error) {
      console.error('Error truncating channel:', error);
    }
    dispatch(clearFlowRun({ flowId: flow.id }));
  };

  const toggleLogVisibility = () => {
    setIsLogVisible(!isLogVisible);
  };

 

  useEffect(() => {
    scrollToBottom();
  }, [channel]);

  if (!flow) return <LoadingScreen message="Please select a flow..." />;
  if (loading || !channel) return <LoadingScreen message="Getting things ready..." />;

  return (
    <div className="output-area">
      <FlowInfo 
        flow={flow} 
        clearMessages={clearMessages} 
        toggleLogVisibility={toggleLogVisibility} 
      />
      <Chat client={chatClient} theme="str-chat__theme-light">
        <Channel channel={channel}>
          <Window>
            <MessageList renderMessages={customRenderMessages} />
            {isTyping && <TypingIndicator />}
          </Window>
        </Channel>
      </Chat>
    </div>
  );
});

export default OutputArea;
