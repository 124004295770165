// InputArea.js
import React, { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { PiCaretCircleDoubleRightFill, PiAlienLight } from "react-icons/pi";
import { setInputValue, clearInputValue, clearInputValues } from '../../../redux/slices/inputSlice';
import { initializeRun, startRun, resumeRun, updateFlowRun, setCurrentInputRequirement } from '../../../redux/slices/flowSlice';
import { selectFlowRun, selectFlow, selectSelectedSpace, selectInputValues, selectStatus, selectCurrentInputRequirement, selectFlowRunStatus } from '../../../redux/selectors';
import { useStreamClient } from '../../../clients/StreamClient';
import './styles/InputArea.css';

// Import necessary Material-UI components
import { Typography, IconButton, Collapse, List, ListItem, ListItemText } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

function InputArea() {
    const dispatch = useDispatch();
    const { getAccessTokenSilently, user } = useAuth0();
    const inputRef = useRef(null);
    const submitButtonRef = useRef(null);
    const { channel } = useStreamClient();
    const flow = useSelector(selectFlow);
    const spaceid = useSelector(selectSelectedSpace);
    const flowRun = useSelector(selectFlowRun);
    const inputValues = useSelector(selectInputValues);
    const status = useSelector(selectStatus);
    const currentInputRequirement = useSelector(selectCurrentInputRequirement);
    const flowRunStatus = useSelector(selectFlowRunStatus);

    const [messageHistory, setMessageHistory] = useState([]);
    const [historyIndex, setHistoryIndex] = useState(-1);

    // New state for collapse functionality
    const [open, setOpen] = useState(false);

    // Focus and auto-resize logic remains the same
    useEffect(() => {
        if (Object.keys(currentInputRequirement).length > 0) {
            inputRef.current?.focus();
            autoResizeTextarea();
        } else {
            submitButtonRef.current?.focus();
        }
        dispatch(clearInputValues());
    }, [currentInputRequirement]);

    const autoResizeTextarea = () => {
        const textarea = inputRef.current;
        if (textarea) {
            textarea.style.height = 'auto';
            textarea.style.height = `${textarea.scrollHeight}px`;
        }
    };

    // Set default input if none is defined
    useEffect(() => {
        const isEmptyObject = (obj) => {
            return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
        };

        if (flowRunStatus !== 'pending' && (currentInputRequirement === null || isEmptyObject(currentInputRequirement)) && flow?.configuration?.inputVariables) {
            dispatch(setCurrentInputRequirement({ flowId: flow.id, inputRequirement: [flow.configuration.inputVariables[0]] }));
        }
    }, [flow, flowRun, currentInputRequirement, dispatch]);

    // Handle submit remains the same
    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!flow) return;

        let message = '...';
        let inputKey = Array.isArray(currentInputRequirement)
            ? currentInputRequirement[0]
            : currentInputRequirement;
        let inputValue = {};

        if (Object.keys(currentInputRequirement).length === 0) {
            if (inputRef.current?.value) {
                message = inputRef.current?.value;
            }
            inputValue = { [inputKey]: message };
        } else {
            if (inputRef.current?.value) {
                message = inputRef.current?.value;
                inputValue = { [inputKey]: message };
                dispatch(setInputValue({ key: inputKey, value: inputRef.current?.value }));
            } else if (inputValues[inputKey]) {
                message = inputKey;
                inputValue = { [inputKey]: inputValues[inputKey] };
            } else return;
        }

        setMessageHistory((prev) => [...prev, message]);
        setHistoryIndex(-1);

        try {
            await sendMessageToChannel(channel, message);

            const accessToken = await getAccessTokenSilently({
                audience: 'https://www.aflow.ai/api',
                scope: 'readApi',
            });

            const updatedInputValues = { [inputKey]: inputValue[inputKey], spaceId: spaceid.value };
            if (flowRunStatus === 'pending') {
                const resumeResult = await dispatch(resumeRun({ flowRunId: flowRun.flowRunId, inputValues: updatedInputValues, userId: user.sub, accessToken })).unwrap();
                dispatch(updateFlowRun({ flowId: flow.id, flowRun: resumeResult }));
            } else {
                const initializeResult = await dispatch(initializeRun({ flowId: flow.id, inputValues: updatedInputValues, userId: user.sub, accessToken })).unwrap();
                dispatch(updateFlowRun({ flowId: flow.id, flowRun: initializeResult }));
                await dispatch(startRun({ flowRunId: initializeResult.flowRunId, accessToken })).unwrap();
            }
            // After the submission is successful, clear the input value
             dispatch(clearInputValue(inputKey));
        } catch (error) {
           console.log('Error sending message:', error);
        }
    };

    // Handle key down for message history remains the same
    const handleKeyDown = (event) => {
        if (event.key === 'ArrowUp') {
            setHistoryIndex((prev) => (prev < messageHistory.length - 1 ? prev + 1 : prev));
        } else if (event.key === 'ArrowDown') {
            setHistoryIndex((prev) => (prev > 0 ? prev - 1 : -1));
        }
    };

    useEffect(() => {
        if (historyIndex >= 0 && historyIndex < messageHistory.length) {
            inputRef.current.value = messageHistory[messageHistory.length - 1 - historyIndex];
        } else {
            if(inputRef.current)
            inputRef.current.value = '';
        }
        autoResizeTextarea();
    }, [historyIndex, messageHistory]);

    // Extract selected data from inputValues
    const inputKey = Array.isArray(currentInputRequirement)
        ? currentInputRequirement[0]
        : currentInputRequirement;

    let selectedData = null;
    try {
        
        selectedData = inputValues[inputKey] ? [inputValues[inputKey]] : null;
        if(selectedData && selectedData.length > 0)  submitButtonRef.current?.focus();
    } catch (error) {
        console.error('Error parsing selected data:', error);
    }

    const handleToggle = () => {
        setOpen(!open);
    };

    return (
        <form onSubmit={handleSubmit} className={Object.keys(currentInputRequirement).length > 0 ? "input-area" : "input-area-alt"}>
    {selectedData && (
        <div className="selected-data">
            <div onClick={handleToggle} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', transition: 'background-color 0.3s ease' }} className={open ? 'expanded' : ''}>
                <Typography variant="subtitle1" style={{ fontWeight: open ? 'bold' : 'normal', transition: 'font-weight 0.3s ease' }}>
                    {Array.isArray(selectedData) ? selectedData.length==1 ?  `${currentInputRequirement}: 1 item`  : `${currentInputRequirement}: ${selectedData.length} items` : ''}
                </Typography>
                <IconButton size="small" style={{ transition: 'transform 0.3s ease', transform: open ? 'rotate(180deg)' : 'rotate(0)', color: 'var(--accent-color-dark)' }}>
                    {open ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
            </div>
            <Collapse in={open} style={{ transition: 'height 0.3s ease' }}>
                <List dense={true}>
                    {Array.isArray(selectedData) ? selectedData.map((item, index) => (
                        <ListItem key={index}>
                            <ListItemText
                                primary={Object.entries(item).map(([key, value]) => `${key}: ${value}`).join(', ')}
                            />
                        </ListItem>
                    )) : (
                        <ListItem>
                            <ListItemText
                                primary={Object.entries(selectedData).map(([key, value]) => `${key}: ${value}`).join(', ')}
                            />
                        </ListItem>
                    )}
                </List>
            </Collapse>
        </div>
    )}



            {Object.keys(currentInputRequirement).length > 0 ? (
                <textarea
                    ref={inputRef}
                    placeholder={currentInputRequirement || ''}
                    className={Object.keys(currentInputRequirement).length > 0 ? "input-field" : "input-field-alt"}
                    rows="4"
                    disabled={status === 'loading'}
                    onInput={autoResizeTextarea}
                    onKeyDown={handleKeyDown}
                />
            ) : null}

            <button
                type="submit"
                ref={submitButtonRef}
                className={Object.keys(currentInputRequirement).length > 0 ? "submit-button" : "submit-button-alt"}
                disabled={status === 'loading'}
            >
                {Object.keys(currentInputRequirement).length > 0 ? (
                    <PiCaretCircleDoubleRightFill className="submit-button-text" />
                ) : (
                    <PiAlienLight className="submit-button-text-alt" />
                )}
            </button>
        </form>
    );
}

export default InputArea;

async function sendMessageToChannel(channel, message) {
    try {
        await channel.sendMessage({
            text: message,
            customType: 'text',
        });
    } catch (error) {
        console.error('Error sending message to channel:', error);
    }
}
