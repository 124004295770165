// Header.js

import '../../styles/Header.css';
import { PiHamburgerLight } from "react-icons/pi";
import AflowLogo from '../../components/AflowLogo';





function Header({ toggleSidebar }) {
  
 
  return (
    <header className='header'>
     <AflowLogo />
      
      <button className="nav">
      <PiHamburgerLight className="burger-menu" onClick={toggleSidebar} />
      </button>
      
    </header>
  );
}

export default Header;

            
            
            
              
            
       
