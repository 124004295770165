import React, { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Tooltip from '@mui/material/Tooltip';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCurrentInputRequirement,
  selectInputValues,
} from '../../../../redux/selectors';
import { setInputValue } from '../../../../redux/slices/inputSlice';
import JSONViewer from 'react-json-view'; 


const DataTable = ({
  jsonData,
  defaultPageSize = 100,
  rowHeight = 52,
  selectionMode = 'none', // 'none', 'single', 'multiple'
}) => {
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const dispatch = useDispatch();
  const currentInputRequirement = useSelector(selectCurrentInputRequirement);
  const inputValues = useSelector(selectInputValues);

  const inputKey = Array.isArray(currentInputRequirement)
    ? currentInputRequirement[0]
    : currentInputRequirement;

  // Parse and process jsonData
  const parsedData = parseJsonData(jsonData);

  if (!parsedData) {
    // If parsing fails, render raw jsonData
    return <div>{jsonData}</div>;
  }

  if (
    !Array.isArray(parsedData) ||
    parsedData.length === 0 ||
    typeof parsedData[0] !== 'object'
  ) {
    return <div>{readableJSON(parsedData)}</div>;
  }

  // Add IDs to each row
  const sanitizedData = parsedData.map((item, index) =>
    typeof item === 'object' && item !== null
      ? { id: index !== undefined ? index : null, ...item } // Only add id if it makes sense
      : item
  );
  

  // Generate columns based on keys
  const columns = generateColumns(sanitizedData);

  // Handle selection change
  const handleSelectionChange = (selectionModel) => {
    setSelectedRowIds(selectionModel);
    //og('Selected Row IDs:', selectionModel);

    // Get the selected data based on IDs
    const selectedData = sanitizedData.filter((row) =>
      selectionModel.includes(row.id)
    );

    // Convert selected data into a string or suitable format
    const selectedDataString = JSON.stringify(selectedData);

    // Dispatch selected data as input under the required input key
    dispatch(setInputValue({ key: inputKey, value: selectedData[0]}));
  };

  // Check if there's only one row to render vertically
  if (sanitizedData.length === 1) {
     
    const rowData = sanitizedData[0];
    return <VerticalTable data={rowData} />;
  }

  // Calculate dynamic height
  const gridHeight = Math.min(
    sanitizedData.length * rowHeight +
      110 -
      (sanitizedData.length <= defaultPageSize ? rowHeight : 0),
    500
  );

  // Styles for DataGrid
  const dataGridStyles = {
   // color: 'var(--primary-text-dark)',
    height: gridHeight,
    fontSize: 'inherit',
    '& .MuiDataGrid-root': {
      backgroundColor: 'transparent',
      color: 'var(--primary-text-dark)',
      borderColor: 'var(--primary-text-dark)',
      outline: 'none',
      fontSize: 'inherit',
    },
    '& .MuiDataGrid-cell:focus': {
      outline: 'none',
    },
    '& .MuiDataGrid-row': {
      backgroundColor: 'transparent',
      color: 'var(--primary-text-dark)',
      borderColor: 'var(--primary-text-dark)',
    },
    '& .MuiDataGrid-row:hover': {
      backgroundColor: 'var(--secondary-bg-dark)',
    },
    '& .MuiDataGrid-row.Mui-selected': {
      backgroundColor: 'var(--secondary-bg-dark)',
      color: 'var(--accent-color-dark)',
    },
    '& .MuiDataGrid-columnHeaders': {
      backgroundColor: 'var(--primary-text-dark)',
     // color: 'var(--primary-bg-dark)',
    },
    '& .MuiTablePagination-root': {
      color: 'var(--border-dark)',
      borderColor: 'var(--primary-text-dark)',
    },
    '& .MuiTablePagination-actions': {
      color: 'var(--accent-color-dark)',
    },
    '& .MuiDataGrid-sortIcon, & .MuiDataGrid-menuIcon, & .MuiTablePagination-actions .svg': {
      color: 'var(--accent-color-dark)',
    },
    '& .MuiCheckbox-root': {
      color: 'var(--secondary-bg-dark)',
    },
    '& .MuiCheckbox-root.Mui-checked': {
      color: 'var(--primary-text-dark)',
    },
  };

  const enableSelection = selectionMode !== 'none';
  const multipleSelection = selectionMode === 'multiple';

  return (
    <div style={{ width: '100%', height: gridHeight }}>
      <DataGrid
        checkboxSelection={enableSelection}
        disableRowSelectionOnClick={!enableSelection}
        disableMultipleRowSelection={!multipleSelection}
        columns={columns}
        rows={sanitizedData}
        pageSize={defaultPageSize}
        rowsPerPageOptions={[10, 25, 50, 100]}
        hideFooter={sanitizedData.length <= defaultPageSize}
        rowHeight={rowHeight}
        columnBuffer={10}
        onRowSelectionModelChange={handleSelectionChange}
        sx={dataGridStyles}
      />
    </div>
  );
};

// Helper functions
function parseJsonData(jsonData) {
  //console.log('PARESEJSON Data:', jsonData);
  if (Array.isArray(jsonData)) return jsonData;
  if (typeof jsonData === 'object' && jsonData !== null) return [jsonData];
  if (typeof jsonData === 'string') {
    try {
      const parsed = JSON.parse(jsonData);
      if (Array.isArray(parsed)) return parsed;
      if (typeof parsed === 'object' && parsed !== null) return [parsed];
    } catch (e) {
      // parsing failed
      return null;
    }
  }
  return null;
}

function generateColumns(data) {
  if (!data || data.length === 0) return [];
  return Object.keys(data[0]).map((key) => ({
    field: key,
    headerName: key.charAt(0).toUpperCase() + key.slice(1),
    width: 150,
    renderCell: (params) => {
      const value = params.value;
      const displayValue =
        typeof value === 'object' && value !== null
          ? JSON.stringify(value)
          : value;
      return (
        <Tooltip title={displayValue} arrow>
          <span>{displayValue}</span>
        </Tooltip>
      );
    },
  }));
}

function VerticalTable({ data }) {
  return (
    <div style={{ padding: '20px', overflowX: 'auto' }}>
      <table
        style={{
          borderCollapse: 'collapse',
          width: '100%',
          border: '1px solid var(--primary-text-dark)',
        }}
      >
        <tbody>
          {Object.entries(data)
            .filter(([key, value]) => !(key === 'id' && value === 0)) // Exclude 'id' if its value is 0
            .map(([key, value]) => (
              <tr
                key={key}
                style={{ borderBottom: '1px solid var(--primary-text-dark)', textWrap: 'wrap' }}
              >
                <th
                  style={{
                    padding: '8px',
                    textAlign: 'left',
                    backgroundColor: 'var(--primary-text-dark)',
                    color: 'var(--primary-bg-dark)',
                    borderRight: '1px solid var(--primary-text-dark)',
                    maxWidth: '30%',
                  }}
                >
                  {key.charAt(0).toUpperCase() + key.slice(1)}
                </th>
                <td
                  style={{
                    padding: '8px',
                    textAlign: 'left',
                    wordBreak: 'break-word',
                  }}
                >
                  {typeof value === 'object' && value !== null
                    ? <JSONViewer
                        src={value}
                        theme="monokai" // You can choose other themes like "rjv-default"
                        collapsed={1}   // Number of levels to collapse
                        enableClipboard={false} // Disable or enable the clipboard option
                        displayDataTypes={false} // Disable or enable data types
                        displayObjectSize={false} // Disable or enable object size display
                  />
                    : value}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
}


function readableJSON(jsonObj) {
  if (typeof jsonObj !== 'object' || jsonObj === null) {
    return '';
  }

  const formattedEntries = Object.entries(jsonObj).map(([key, value]) => {
    const isNumericKey = !isNaN(key);
   // if(isNumericKey) return null; 
    const capitalizedKey = isNumericKey
      ? ''
      : key.charAt(0).toUpperCase() + key.slice(1);

    if (Array.isArray(value)) {
      return (
        <div key={key}>
          {isNumericKey ? null : <strong>{capitalizedKey}: </strong>}
          <DataTable jsonData={value} />
        </div>
      );
    }

    const formattedValue =
      typeof value === 'object' && value !== null
        ? readableJSON(value)
        : value;

    return (
      <div key={key}>
        {isNumericKey ? null : <strong>{capitalizedKey}: </strong>}
        {formattedValue}
      </div>
    );
  });

  return <div>{formattedEntries}</div>;
}

export default DataTable;
