// src/utils/themeUtils.js

export const applyTheme = (theme) => {
    document.body.className = theme === 'dark' ? 'dark-mode' : 'light-mode';
  
    const themeColors = theme === 'dark' ? {
      '--primary-bg-dark': '#121212',
      '--primary-text-dark': '#f0ebde',//e0e0e0
      '--accent-color-dark': '#FF4B4B',
      '--highlight-color': '#FFF',
      '--button-bg-dark': '#333',
      '--secondary-bg-dark': '#2f2f2f',
      '--button-text-dark': '#f5f5f5',
      '--link-color-dark': '#999',
      '--border-dark': '#444',
      '--border-data-dark': '#f1f1f1',
      '--icon-dark': '#ccc'
    } : {
      '--primary-bg-dark': '#f1f1f1', //'#f1f1f1',
      '--primary-text-dark': '#333333',
      '--accent-color-dark': '#FF4B4B',
      '--highlight-color': '#000',
      '--button-bg-dark': '#333',
      '--secondary-bg-dark': '#ddd',
      '--button-text-dark': '#4f4f4f',
      '--link-color-dark': '#777',
      '--border-dark': '#000',//'#ccc'
      '--border-data-dark': '#000',
      '--icon-dark': '#333'
    };
  
    Object.keys(themeColors).forEach(key => {
      document.body.style.setProperty(key, themeColors[key]);
    });
   // document.body.style.setProperty('--second-font', "'Roboto Mono', monospace");
   // document.body.style.setProperty('--second-font', "'apple-system, BlinkMacSystemFont', monospace");
    
  };
  