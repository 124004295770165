import React, { useState } from 'react';
import { PiChatSlash, PiEye, PiEyeSlash } from 'react-icons/pi';
import FlowRunStatusIndicator from './FlowRunStatusIndicator';
import FlowVisualizer from './FlowVisualizer';
import FlowRunDisplay from './FlowRunDisplay'; // Import the new FlowRunDisplay component
import { selectFlow } from '../../../../redux/selectors';
import { useSelector } from 'react-redux';
import { ReactFlowProvider } from 'reactflow';

const FlowInfo = ({ clearMessages, toggleLogVisibility }) => {
  const [showVisualizer, setShowVisualizer] = useState(false);
  const [showFlowRunDisplay, setShowFlowRunDisplay] = useState(false); // State to toggle FlowRunDisplay
  const flow = useSelector(selectFlow);
  if (!flow) return null;

  const toggleVisualizerVisibility = () => {
    setShowVisualizer(!showVisualizer);
  };

  const toggleFlowRunDisplayVisibility = () => {
    setShowFlowRunDisplay(!showFlowRunDisplay); // Toggle FlowRunDisplay visibility
  };

  return (
    <div className="flow-info-container">
      {flow && (
        <div className="flow-info">
          <div className="flow-info-header">
            <div className="flow-info-text">
              <h2>{flow.name}</h2>
            </div>

            <button onClick={toggleFlowRunDisplayVisibility} title="Toggle Flow Run Display"> {/* Add toggle button */}
              <FlowRunStatusIndicator />
            </button>

            <button onClick={clearMessages} title="Clear Messages">
              <PiChatSlash size={24} />
            </button>

            <button className="visualiserToggle" onClick={toggleVisualizerVisibility} title="Toggle Visualizer">
              {showVisualizer ? <PiEyeSlash size={24} /> : <PiEye size={24} />}
            </button>
          </div>

          {/* ReactFlowProvider ensures that FlowVisualizer works correctly */}
          <ReactFlowProvider>
            <div className={showVisualizer ? 'flow-info-visualizer' : 'flow-info-visualizer-hidden'}>
              <p>{flow.summary ? flow.summary : flow.description}</p>
              <FlowVisualizer flowConfig={flow.configuration} />
            </div>
          </ReactFlowProvider>

          {/* Conditionally render FlowRunDisplay based on showFlowRunDisplay */}
          {showFlowRunDisplay && <FlowRunDisplay />}
        </div>
      )}
    </div>
  );
};

export default FlowInfo;
