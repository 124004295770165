import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import InputArea from './InputArea';
import OutputArea from './OutputArea';
import './styles/FlowInterface.css';
import LoadingScreen from '../../../components/LoadingScreen';
import { selectFlow, selectStatus } from '../../../redux/selectors';
import { useStreamClient } from '../../../clients/StreamClient';
import { generateChannelId, sanitizeUserId } from '../../../utils/helpers';

function FlowInterface() {
  const { accessToken, user } = useSelector((state) => state.user);
  const flow = useSelector(selectFlow);
  const status = useSelector(selectStatus);
  const { chatClient, setChannel, isUserConnected } = useStreamClient();
 
  


  useEffect(() => {
    if (flow && user && accessToken && isUserConnected) {
      const sanitizedUserId = sanitizeUserId(user.sub);
      const chanID = generateChannelId(flow.id, user.sub);

      const connectToChannel = async () => {
        const newChannel = chatClient.channel('messaging', chanID, {
          members: [sanitizedUserId],
          own_capabilities: ['read', 'write', 'join', 'add-members', 'AddOwnChannelMembership'],
          extra_data: { virtual_user_role: 'virtualUser-Flow' },
        });
        await newChannel.watch();
        setChannel(newChannel);
        console.log('Connected to channel:', newChannel);
      };

      connectToChannel();
    }
  }, [flow, user, accessToken, isUserConnected, chatClient, setChannel]);


  if (status === 'loading') {
    return <LoadingScreen message="Getting things ready..." />;
  }

  if (status === 'failed') {
    return <LoadingScreen message="Loading flow failed." />;
  }

  return (
    <div className="flow-interface">
      <div className="top-container">
        <OutputArea  />
        <InputArea />
       
      </div>
    </div>
  );
}


export default FlowInterface;
