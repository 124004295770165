import React, { useEffect, useRef } from 'react';
import ReactFlow, {
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  addEdge,
  ReactFlowProvider,
  useReactFlow,
  Position,
} from 'reactflow';
import 'reactflow/dist/style.css';
import { StepNode, VariableNode } from './CustomNodes';
import dagre from 'dagre';

const nodeTypes = {
  stepNode: StepNode,
  variableNode: VariableNode,
};

const nodeWidth = 250;
const nodeHeight = 80;

const getLayoutedElements = (nodes, edges) => {
  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));
  dagreGraph.setGraph({ rankdir: 'LR', nodesep: 50, ranksep: 100 });

  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, { width: node.width || nodeWidth, height: node.height || nodeHeight });
  });

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  nodes.forEach((node) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    node.position = {
      x: nodeWithPosition.x,
      y: nodeWithPosition.y,
    };
  });
  return { nodes, edges };
};

const FlowVisualizer = ({ flowConfig }) => {
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const { fitView, setViewport } = useReactFlow();
  const layoutRef = useRef(false); // Add a ref to prevent redundant fitView calls

  useEffect(() => {
    if (flowConfig) {
      const initialNodes = [];
      const initialEdges = [];

      const { steps, variables, inputVariables, outputVariables } = flowConfig;
      if (!steps || Object.keys(steps).length === 0) return;

      // Clear existing nodes and edges
      setNodes([]);
      setEdges([]);

      Object.keys(steps).forEach((stepId) => {
        const step = steps[stepId];
        initialNodes.push({
          id: stepId,
          type: 'stepNode',
          data: {
            name: step.name,
            stepType: step.stepType,
            operation: step.operation,
            stepConfig: step.stepConfig,
            inputs: step.inputs,
            output: step.output,
          },
          position: { x: 0, y: 0 },
        });

        // Add edges for next steps
        if (step.nextSteps) {
          step.nextSteps.forEach((nextStepId) => {
            initialEdges.push({
              id: `${stepId}-${nextStepId}`,
              source: stepId,
              target: nextStepId,
              type: 'default',
              sourcePosition: Position.Right,
              targetPosition: Position.Left,
              markerEnd: { type: 'arrowclosed' },
            });
          });
        }

        // Add edges for step inputs
        if (step.inputs) {
          step.inputs.forEach((input) => {
            initialEdges.push({
              id: `${input}-${stepId}`,
              source: input,
              target: stepId,
              type: 'default',
              sourcePosition: Position.Left,
              targetPosition: Position.Right,
              markerEnd: { type: 'arrowclosed' },
              animated: true,
            });
          });
        }

        // Add edges for step outputs
        step.output.forEach((output) => {
          initialEdges.push({
            id: `${stepId}-${output}`,
            source: stepId,
            target: output,
            type: 'default',
            sourcePosition: Position.Right,
            targetPosition: Position.Left,
            markerEnd: { type: 'arrowclosed' },
            animated: true,
          });
        });
      });

      Object.keys(variables).forEach((variableName) => {
        const variable = variables[variableName];
        const isInput = inputVariables.includes(variableName);
        const isOutput = outputVariables.includes(variableName);

        initialNodes.push({
          id: variableName,
          type: 'variableNode',
          data: {
            name: variableName,
            type: variable.type,
            value: variable.value,
            isInput,
            isOutput,
          },
          position: { x: 0, y: 0 },
        });
      });

      const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(initialNodes, initialEdges);
      setNodes(layoutedNodes);
      setEdges(layoutedEdges);

      // Set layoutRef to true to prevent repeated fitView calls
      layoutRef.current = true;
    }
  }, [flowConfig, setNodes, setEdges]);

  useEffect(() => {
    // Trigger fitView only after nodes and edges have been set and layout has been processed
    if (layoutRef.current && nodes.length > 0 && edges.length > 0) {
      fitView();
      layoutRef.current = false; // Reset the flag after fitting the view
    }
  }, [nodes, edges, fitView]);

  const onConnect = (params) => setEdges((eds) => addEdge(params, eds));

  const proOptions = { hideAttribution: true };

  return (
    <ReactFlowProvider>
      {nodes.length > 0 && edges.length > 0 && (
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          nodeTypes={nodeTypes}
          proOptions={proOptions}
        >
          <Controls />
          <Background />
        </ReactFlow>
      )}
    </ReactFlowProvider>
  );
};

export default FlowVisualizer;
