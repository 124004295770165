import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import flowIcon from '../../../images/aflow-icon-sq-anim.png';
import { shortenUserId } from '../../../utils/helpers';
import { PiArrowBendDownRightFill } from "react-icons/pi";
import JSONOutput from './JSONOutput';
import VisualDataOutput from './VisualDataOutput';
import { updateFlowRun, setCurrentInputRequirement } from '../../../redux/slices/flowSlice';
import { selectFlowRun } from '../../../redux/selectors';
import { fetchFlowRunVariables } from '../../../services/flowService';
import './styles/chat.css';

const valueToString = (value) => {
  if (typeof value === 'string') return value;
  if (value && typeof value === 'object') return JSON.stringify(value, null, 2);
  return String(value);
};

const FlowMessage = React.memo((props) => {
  const message = props.message || props; 
  const { user } = useSelector((state) => state.user);
  const { getAccessTokenSilently } = useAuth0();
  const userClass = message.user
    ? shortenUserId(message.user.id) === shortenUserId(user.sub)
      ? 'me'
      : 'others'
    : 'system';
  const isFlowMessage = message.user?.id?.startsWith('flow-');
  const flowRun = useSelector(selectFlowRun);
  const dispatch = useDispatch();
  const previousCustomTypeRef = useRef();
  const [variables, setVariables] = useState(
    Array.isArray(message.outputVariables) ? message.outputVariables : []
  );

  useEffect(() => {
    const previousCustomType = previousCustomTypeRef.current;

    const fetchVariables = async () => {
      try {
        if(message.outputVariables){
          const fetchedVariables = message.outputVariables;
          setVariables(Array.isArray(fetchedVariables) ? fetchedVariables : []);
        }
        else
        {
          if((flowRun?.status === 'pending' || flowRun.status === 'completed') && message.flowRun_id === flowRun?.flowRunId)
            {
            const accessToken = await getAccessTokenSilently({
              audience: 'https://www.aflow.ai/api',
              scope: 'readApi',
            });
            const fetchedVariables1 = await fetchFlowRunVariables(
            message.flowRun_id,
            message.outputVariablesNames,
            accessToken
           );
           
           setVariables(Array.isArray(fetchedVariables1) ? fetchedVariables1 : []);
          }
        }
      } catch (error) {
        console.error('Error fetching flow run variables:', error);
      }
    };

    if (message.outputVariablesNames && message.flowRun_id) {
      fetchVariables();
    }
   
    if (message.flow_id && message.flowRun_id ) {
      if (message.customType === 'FlowCompleted' && previousCustomType !== 'FlowCompleted') {
        dispatch(
          updateFlowRun({
            flowId: message.flow_id,
            flowRun: { ...flowRun, flowRunId: message.flowRun_id, status: 'completed' },
          })
        );
      } else if (message.customType === 'InputRequired' && previousCustomType !== 'InputRequired') {
        dispatch(
          updateFlowRun({
            flowId: message.flow_id,
            flowRun: { ...flowRun, flowRunId: message.flowRun_id, status: 'pending' },
          })
        );
        dispatch(
          setCurrentInputRequirement({
            flowId: message.flow_id,
            inputRequirement: message.inputRequired,
          })
        );
      } else if (message.customType === 'FlowFailed' && previousCustomType !== 'FlowFailed') {
        dispatch(
          updateFlowRun({
            flowId: message.flow_id,
            flowRun: { ...flowRun, flowRunId: message.flowRun_id, status: 'failed' },
          })
        );
      }
    }

    previousCustomTypeRef.current = message.customType;
  }, [dispatch, flowRun, getAccessTokenSilently, message]);

  const renderFormattedOutput = useCallback(
    (outputArray) => {
      const outputs = Array.isArray(outputArray) ? outputArray : [outputArray];
      return outputs.map((output, index) => {
        if (output?.type && output?.type === 'visual_data') {
          return (
            <VisualDataOutput
              key={index}
              output={output}
              attachments={message.attachments}
            />
          );
        }
        if (output?.type && output?.type === 'json') {
          return (
            <div  key={index}>
            <JSONOutput
             
              output={output}
              select={
                flowRun?.status === 'pending' &&
                message.flowRun_id === flowRun?.flowRunId
              }
            /></div>
          );
        }
        if (output?.type && output?.type === 'jsonArray') {
          return (
            <div key={index}>
            <JSONOutput
             
              output={output}
              select={
                flowRun?.status === 'pending' &&
                message.flowRun_id === flowRun?.flowRunId
                  ? 'multiple'
                  : true
              }
            /></div>
          );
        }
        if (output?.value && output?.value) {
          return (
            <div key={index} style={{ marginBottom: '10px' }}>
              {valueToString(output.value)}
            </div>
          );
        }
        return null;
      });
    },
    [flowRun, message.attachments, message.flowRun_id]
  );

  const renderContent = () => {
    if (message.customType === 'message.date') {
      return <p>{new Date(message.date).toLocaleString()}</p>;
    }

    if (message.customType === 'FlowCompleted') {
      const outputs =
        Array.isArray(variables) && variables.length > 0 ? variables : [message.text];
        const minhigh =
        message.variablesLength && message.variablesLength > 10 ? 'minhigh' : 'nominhigh';
      return (
        <div className={minhigh}>
       
        {renderFormattedOutput(outputs)}
       
        </div>
    
    );
    }

    if (message.customType === 'InputRequired') {
      const requiredVariables = message.inputRequired || [];
      const outputs =
        Array.isArray(variables) && variables.length > 0 ? variables : message.text;
        const minhigh =
        message.variablesLength && message.variablesLength > 10 ? 'minhigh' : 'nominhigh';
      return (
        <div className={minhigh}>
          {renderFormattedOutput(outputs)}
          <p>{ (typeof message.inputPrompt === 'object' ) 
          ? <JSONOutput output={message.inputPrompt}/> 
          : ( message.inputPrompt || 'Please provide the following:')}</p>
          {requiredVariables.map((input, index) => (
            <div key={index} className="message-input">
              <PiArrowBendDownRightFill />
              <div className="input-text">
                <strong>{input}</strong>
              </div>
            </div>
          ))}
        </div>
      );
    }

    return <p>{message.text}</p>;
  };

  const renderMessage = () => {
    if (message.customType === 'FlowFailed') {
      return (
        <div className="message-error" key={message.id}>
          <p>{message.text}</p>
        </div>
      );
    }

    return (
      <div className={`message-${userClass}`} key={message.id}>
        {isFlowMessage ? (
          <img src={flowIcon} alt="Flow Bot" className="avatar" />
        ) : (
          message.user?.image && (
            <img
              src={message.user.image}
              alt={message.user.name}
              className="avatar"
            />
          )
        )}
        <div className={`message-inner-${userClass}`}>{renderContent()}

        <div className="message-notes">{message.tokenCount && message.tokenCount > 0 ? `${message.tokenCount}  tokens ✧˖°` : null}</div>

        </div>
      </div>
    );
  };

  return renderMessage();
});

export default FlowMessage;
