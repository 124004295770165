import React, { useState } from 'react';
import { fetchFlowRun } from '../../../../services/flowService';
import { useSelector } from 'react-redux';
import { selectFlowRun } from '../../../../redux/selectors';
import { useAuth0 } from '@auth0/auth0-react'; 
import JSONViewer from 'react-json-view'; 
import { RiFlowChart } from "react-icons/ri";



const FlowRunDisplay = () => {
  const [flowRunData, setFlowRunData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const flowRun = useSelector(selectFlowRun);
  const flowRunStatus = flowRun?.status || 'undefined';

  const { getAccessTokenSilently } = useAuth0();

  // Function to handle refresh button click
  const handleRefresh = async () => {
    setLoading(true);  // Start loading
    setError(null);    // Clear previous errors
    try {
      // Fetch the access token
      const accessToken = await getAccessTokenSilently({
        audience: 'https://www.aflow.ai/api',  // Your audience
        scope: 'readApi',                     // Your API scope
      });

      // Call the flowService with the access token
      if (!flowRun.flowRunId) {
        throw new Error('No flow run found');
        return
      }
      
      const data = await fetchFlowRun(flowRun.flowRunId,accessToken);
      setFlowRunData(data); // Set the fetched data in state
    } catch (err) {
      setError(`${err.message}`, ); // Handle error
      setFlowRunData(null); // Clear data
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <div style={{fontSize : 8, textAlign: 'right'}}>
      <button onClick={handleRefresh} disabled={loading} style={{textAlign : 'right'}}>
        {loading ? 'Refreshing...' : 'Server flowrun'}
      </button>

      {/* Display data, loading, or error */}
      {error && <div style={{ paddingRight:5, color: "var(--accent-color-dark)" }}>{error}</div>}
      {loading && <div>Loading...</div>}
      <div style={{maxHeight: 400 , overflow: 'scroll', textAlign: 'left', fontSize:10}}>
      {flowRunData && (
        <JSONViewer
          src={flowRunData}
          theme="monokai" // You can choose other themes like "rjv-default"
          collapsed={2}   // Number of levels to collapse
          enableClipboard={false} // Disable or enable the clipboard option
          displayDataTypes={false} // Disable or enable data types
          displayObjectSize={false} // Disable or enable object size display
        />
      )}
    </div>
    </div>
  );
};

export default FlowRunDisplay;
