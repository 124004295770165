import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { initializeFlowRun, startFlowRun, resumeFlowRun } from '../../services/flowService';

// Thunks for asynchronous operations
export const initializeRun = createAsyncThunk(
  'flowRun/initializeRun',
  async ({ flowId, inputValues, userId, accessToken }) => {
    const response = await initializeFlowRun(flowId, inputValues, userId, accessToken);
    return response;
  }
);

export const startRun = createAsyncThunk(
  'flowRun/startRun',
  async ({ flowRunId, accessToken }) => {
    const response = await startFlowRun(flowRunId, accessToken);
    return response;
  }
);

export const resumeRun = createAsyncThunk(
  'flowRun/resumeRun',
  async ({ flowRunId, inputValues, userId, accessToken }) => {
    const response = await resumeFlowRun(flowRunId, inputValues, userId, accessToken);
    return response;
  }
);

// Initial state
const initialState = {
  flowRun: null,
  status: 'idle',
  currentInputRequirement: null,
  error: null,
};

// Slice definition
const flowRunSlice = createSlice({
  name: 'flowRun',
  initialState,
  reducers: {
    updateFlowRun: (state, action) => {
      // Avoid updating if the flowRun object hasn't changed
      if (JSON.stringify(state.flowRun) !== JSON.stringify(action.payload)) {
        state.flowRun = action.payload;
      }
    },
    setFlowRunStatus: (state, action) => {
      if (state.flowRun && state.flowRun.status !== action.payload) {
        state.flowRun.status = action.payload;
      }
    },
    setCurrentInputRequirement: (state, action) => {
      if (state.currentInputRequirement !== action.payload) {
        state.currentInputRequirement = action.payload;
      }
    },
    resetFlowRun: (state) => {
      state.flowRun = null;
      state.status = 'idle';
      state.currentInputRequirement = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(initializeRun.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(initializeRun.fulfilled, (state, action) => {
        state.flowRun = action.payload;
        state.status = 'succeeded';
        state.currentInputRequirement = 'Flow is running...';
      })
      .addCase(initializeRun.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(startRun.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(startRun.fulfilled, (state, action) => {
        state.flowRun = { ...state.flowRun, ...action.payload };
        state.status = 'succeeded';
      })
      .addCase(startRun.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(resumeRun.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(resumeRun.fulfilled, (state, action) => {
        state.flowRun = action.payload;
        state.status = 'succeeded';
      })
      .addCase(resumeRun.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

// Export actions and reducer
export const { updateFlowRun, setFlowRunStatus, setCurrentInputRequirement, resetFlowRun } = flowRunSlice.actions;
export default flowRunSlice.reducer;
